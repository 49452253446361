import { defineStore } from "pinia";
import { useHandleError } from "~/composables/useHandleError";
import { useReport } from "~/composables/useReport";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";

const useRestaurantAvailableDates = defineStore("restaurantAvailableDates", {
  state: () => {
    return {
      availableDate: [] as {
        availability: boolean;
        seatLeft: number;
        date: string;
        bookedSeat: number;
        minSeat: number;
        maxSeat: number;
      }[],
      isLoadingAvailableDate: false,
      isDataLoaded: false,
    };
  },
  actions: {
    async getAvailableDates(
      replaceData: boolean,
      payload: {
        restaurantId: number | string;
        isDineIn?: boolean;
        adult: number;
        kids: number;
        startDate: string;
        endDate: string;
        packageIds?: (number | string)[];
      }
    ) {
      try {
        this.isLoadingAvailableDate = true;
        const {
          restaurantId,
          adult,
          kids,
          startDate,
          endDate,
          packageIds,
          isDineIn,
        } = payload;
        const payloadToSend = {
          restaurantId,
          isDineIn: isDineIn ?? true,
          adult,
          startDate,
          endDate,
          kids,
          packageIds,
        };
        const { getAvailableDates } = await import(
          "~/api/restaurant/getAvailableDates"
        );
        const { data } = await getAvailableDates(payloadToSend);
        const responseData = data;
        if (replaceData) {
          this.availableDate = responseData;
        } else {
          this.availableDate.push(...responseData);
        }

        this.isDataLoaded = true;
      } catch (error) {
        this.isDataLoaded = false;
        const defaultErrorMessage =
          "Oops, something went wrong, failed get restaurant pictures";
        useHandleError({
          defaultErrorMessage,
          err: error,
          errorPath: "restaurantDetail/useRestaurantAvailableTimes",
        });
      } finally {
        this.isLoadingAvailableDate = false;
      }
    },
    async abortGetAvailabilityDate() {
      try {
        const { abortGetAvailabilityDate } = await import(
          "~/api/restaurant/getAvailableDates"
        );
        abortGetAvailabilityDate();
      } catch (err) {
        const message = `${DEFAULT_ERROR_MESSAGE}, failed abort get available dates`;
        useReport({
          level: "error",
          message,
          errorException: err,
        });
      }
    },
  },
});

export { useRestaurantAvailableDates };
