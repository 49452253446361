import { ref, computed, watch, toRaw, type Ref } from "vue";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { chunk, first, last } from "lodash-es";
import { useReport } from "./useReport";
import { useRestaurantAvailableDates } from "~/stores/restaurant/restaurantAvailableDates";
import { useBookingPackageStore } from "~/stores/booking";
import type { CalendarMonth } from "~/types/Calendar";
import { retryablePromise } from "~/helpers/retryablePromise";
import { addIsBetweenPlugin } from "~/lib/dayjs";
import { generateDates } from "~/helpers/dateTime";

interface PayloadAvailableDateType {
  startDate: string;
  endDate: string;
  restaurantId: string | number;
  adult: number;
  kids: number;
  packageIds: string[] | number[];
  isDineIn?: boolean;
}

interface Props {
  selectedDate: Ref<string>;
  daysInAdvance: Ref<number>;
  adultAmount: Ref<number>;
  kidsAmount: Ref<number>;
  restaurantId: Ref<string | number>;
  isBigGroup?: Ref<boolean>;
}

export function useAvailableDate(
  {
    selectedDate,
    daysInAdvance,
    adultAmount,
    kidsAmount,
    restaurantId,
    isBigGroup,
  }: Props,
  resetDate?: Function
) {
  const isReady = ref(false);
  const today = ref(dayjs());
  const startDate = ref(dayjs());
  const endDate = ref(dayjs().add(daysInAdvance.value - 1, "day"));
  const isLoadingAvailableDate = ref(false);
  const restaurantAvailableDatesStore = useRestaurantAvailableDates();
  const { availableDate } = storeToRefs(restaurantAvailableDatesStore);

  const calendar = computed(() => {
    const calendar: Array<CalendarMonth> = [];
    let currentMonth: dayjs.Dayjs = startDate.value.startOf("month");
    const endMonth: dayjs.Dayjs = endDate.value.endOf("month");
    if (!isReady.value) {
      return calendar;
    }
    while (currentMonth.isBefore(endMonth)) {
      const month: CalendarMonth = {
        label: currentMonth.format("MMMM"),
        year: currentMonth.year(),
        weeks: [],
      };
      const firstDay: dayjs.Dayjs = currentMonth
        .startOf("month")
        .startOf("week");
      const lastDay: dayjs.Dayjs = currentMonth.endOf("month").endOf("week");
      for (
        let i: dayjs.Dayjs = firstDay;
        i.isBefore(lastDay);
        i = i.add(1, "day")
      ) {
        let week = month.weeks[month.weeks.length - 1];
        if (!week || week.days.length === 7) {
          week = { days: [] };
          month.weeks.push(week);
        }
        week.days.push({
          date: i.format("YYYY-MM-DD"),
          isToday: i.isSame(today.value, "day"),
          isSelected: i.isSame(selectedDate.value, "day"),
          isLoading: checkLoadingDate(i),
          isCurrentMonth: i.isSame(currentMonth, "month"),
          isInRange: i.isBetween(startDate.value, endDate.value, "day", "[]"),
          isOutOfRange:
            i.isBefore(startDate.value, "day") ||
            i.isAfter(endDate.value, "day"),
          isAvailable: checkAvailableDate(i),
        });
      }
      calendar.push(month);
      currentMonth = currentMonth.add(1, "month");
    }
    return calendar;
  });

  function checkAvailableDate(i: dayjs.Dayjs) {
    if (isBigGroup?.value) return true;
    return (
      availableDate.value.filter(
        (date) => date.date === i.format("YYYY-MM-DD")
      )[0]?.availability || false
    );
  }

  function checkLoadingDate(i: dayjs.Dayjs) {
    if (isBigGroup?.value) return false;
    return !toRaw(availableDate.value).some(
      (date) => date.date === i.format("YYYY-MM-DD")
    );
  }

  const startAndEndDates = computed(() => {
    // get range dates based on daysInAdvance
    const AllDates = generateDates({ length: daysInAdvance.value });
    //  split into chunk with maxium 10 value per chunk
    const chunkDates = chunk(AllDates, 10);
    /// get only first dates and end dates
    const startAndEndDatesChunk = chunkDates?.map((date) => {
      return { startDate: first(date), endDate: last(date) };
    });
    return startAndEndDatesChunk;
  });

  const bookingPackageStore = useBookingPackageStore();
  const { selectedPackages } = storeToRefs(bookingPackageStore);
  const payloadRestaurantAvailableDate = computed(() => {
    // For big group, set adults to 1 and omit package IDs to enable all dates
    const groupPayloadByMonth = startAndEndDates.value.reduce((acc, date) => {
      const month = dayjs(date.startDate).format("YYYY-MM");
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push({
        startDate: date.startDate || "",
        endDate: date.endDate || "",
        restaurantId: restaurantId.value,
        adult: adultAmount.value,
        kids: kidsAmount.value,
        packageIds: selectedPackages.value?.map((item) => item.id),
        isDineIn: bookingPackageStore.isDineInPackages,
      });
      return acc;
    }, {} as Record<string, PayloadAvailableDateType[]>);
    return Object.values(groupPayloadByMonth);
  });

  const monthIndex = ref(0);
  const isOutletIsFullyBooked = ref(false);
  const failedRequests = ref<Array<{ monthIndex: number }>>([]);
  const successRequests = ref<Array<{ monthIndex: number }>>([]);

  function fetchAvailableDate() {
    // if isBigGroup, skip fetching available date
    if (isBigGroup?.value) return;
    isLoadingAvailableDate.value = true;
    const currentMonthPayload =
      payloadRestaurantAvailableDate.value[monthIndex.value];

    const fetchPromises = currentMonthPayload?.map((payload) => {
      return restaurantAvailableDatesStore
        .getAvailableDates(false, payload)
        .then(() => {
          return { status: "fulfilled" };
        })
        .catch((error) => {
          return { status: "rejected", reason: error };
        });
    });

    Promise.allSettled(fetchPromises)
      .then((results) => {
        const allSuccess = results.every(
          (result) => result.status === "fulfilled"
        );
        if (allSuccess) {
          successRequests.value.push({ monthIndex: monthIndex.value });
          setAvailableMonthIndex();
        } else {
          failedRequests.value.push({ monthIndex: monthIndex.value });
        }
        isLoadingAvailableDate.value = false;
      })
      .catch(() => {
        isLoadingAvailableDate.value = false;
      });
  }

  function retryAllFailedRequests() {
    failedRequests.value.forEach((request) => {
      monthIndex.value = request.monthIndex;
      fetchAvailableDate();
    });
  }

  function setAvailableMonthIndex() {
    const currentMonth = calendar.value[monthIndex.value];
    const availableMonth = currentMonth.weeks.findIndex((week) => {
      return week.days.some((day) => {
        return day.isAvailable;
      });
    });
    if (availableMonth === -1) {
      if (monthIndex.value < payloadRestaurantAvailableDate.value.length - 1) {
        // if the current month is not available, move to the next month
        monthIndex.value += 1;
        fetchAvailableDate();
      } else {
        isOutletIsFullyBooked.value = true;
      }
      resetDate && resetDate();
    }
  }

  watch(monthIndex, (value) => {
    // if index not in the successRequests, fetch the available date
    if (
      !successRequests.value.some((request) => request.monthIndex === value)
    ) {
      monthIndex.value = value;
      fetchAvailableDate();
    }
  });

  async function onMountedCallback() {
    try {
      await retryablePromise(() => addIsBetweenPlugin());
      isReady.value = true;
    } catch (err) {
      useReport({
        errorException: err,
        level: "error",
        message: "failed load dayjs isBetween Plugin",
      });
    }
  }

  // Return the state and methods that you want to expose
  return {
    isReady,
    today,
    startDate,
    endDate,
    isLoadingAvailableDate,
    monthIndex,
    isOutletIsFullyBooked,
    failedRequests,
    successRequests,
    calendar,
    availableDate,
    retryAllFailedRequests,
    onMountedCallback,
    fetchAvailableDate,
  };
}
